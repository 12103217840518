import React, { useState } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import { motion } from 'framer-motion'

import { Layout } from '../components'
import { Intro } from '../components'
import { Fade } from '../utils'

import { container, padding, bgImage } from '../styles/global'
import { media, useBreakpoint } from '../styles/utils'
import {
    heading1,
    heading2,
    heading3,
    subheading1,
    subheading2,
    body,
    navigation,
    links,
    caption,
    numbers,
} from '../styles/type'
import { brown3, brown1, brown4, brown2 } from '../styles/colors'
import arrow from '../assets/images/icons/arrow-brown.svg'

const Expertise = props => {
    const graphData = useStaticQuery(query).allWordpressInfopages.nodes[0]
    const data = JSON.parse(graphData.acf_json)
    const isPhone = useBreakpoint('phone')
    // console.log(data)

    const renderServicesNav = () => {
        const items = data.services.map((item, i) => {
            return (
                <Service key={i} whileHover={'hover'} {...animatedNavLink}>
                    <ServiceLink to={`/service/${item.slug}`}>
                        {item.title}
                        <Icon src={arrow} />
                    </ServiceLink>
                </Service>
            )
        })

        return (
            <ServicesNav>
                <Fade delay={1200}>
                    <Container>
                        <Grid>{items}</Grid>
                    </Container>
                </Fade>
            </ServicesNav>
        )
    }

    const renderServicesGrid = () => {
        const items = data.services.map((item, i) => {
            return (
                <ServiceLink key={i} to={`/service/${item.slug}`}>
                    <ServiceGridTransition
                        key={item.image.ID}
                        whileHover={'hover'}
                    >
                        <ImageWrapper>
                            <Image
                                key={item.image.ID}
                                src={
                                    item.image.sizes
                                        ? item.image.sizes.medium2
                                        : null
                                }
                            >
                                {(src, loading) => {
                                    return (
                                        <ServiceGridBGImage
                                            image={src}
                                            key={item.image.ID}
                                            {...animatedGridImage}
                                        />
                                    )
                                }}
                            </Image>
                        </ImageWrapper>

                        <Description>
                            <ServiceGridInfo
                                key={item.image.ID}
                                {...animatedGridInfo}
                            >
                                <Heading>{item.title}</Heading>

                                <ServiceGridLabel
                                    key={item.image.ID}
                                    {...animatedGridLink}
                                >
                                    Learn More
                                </ServiceGridLabel>
                            </ServiceGridInfo>
                        </Description>
                    </ServiceGridTransition>
                </ServiceLink>
            )
        })

        return (
            <ServicesGrid>
                <Container>
                    <Grid>{items}</Grid>
                </Container>
            </ServicesGrid>
        )
    }

    return (
        <Layout
            meta={data && data.seo}
            desktopHeaderTheme={'dark'}
        >
            <Intro
                heading={data.intro_heading}
                subheading={data.intro_subheading}
                description={data.intro_text}
            />
            {renderServicesNav()}
            {renderServicesGrid()}
        </Layout>
    )
}

// Shared

const Heading = styled.h1``
const Subheading = styled.h2``
const Description = styled.div``
const Info = styled.div``
const Service = styled(motion.div)``
const ServiceLink = styled(Link)``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Container = styled.div`
    ${container}
    ${padding}
`
const Grid = styled.div``

// Animations

const animatedNavLink = {
    initial: {
        y: 0,
    },
    variants: {
        hover: {
            y: '-6px',
        },
    },
    transition: { duration: 0.3 },
}

const animatedGridImage = {
    initial: {
        scale: 1.1,
        opacity: 1,
    },
    variants: {
        hover: {
            scale: 1.01,
            opacity: 0.8,
        },
    },
    transition: { duration: 1 },
}

const animatedGridInfo = {
    initial: {
        y: 0,
    },
    variants: {
        hover: {
            y: '-12px',
        },
    },
    transition: { duration: 0.3 },
}

const animatedGridLink = {
    variants: {
        hover: {
            borderBottomColor: `${brown1}`,
            color: `${brown1}`,
        },
    },
    transition: { duration: 0.3 },
}

// Services Nav

const Icon = styled.img``
const ServicesNav = styled.div`
    background-color: ${brown3};

    ${media.phone`
        background-color: white;
    `}

    ${Grid} {
        display: flex;
        flex-wrap: wrap;
        width: 820px;
        max-width: 100%;
        margin: 0 -10px;

        ${media.phone`
            margin: 0 -5px;
        `}
    }

    ${Service} {
        width: 25%;
        padding: 6px 10px 0;
        box-sizing: border-box;

        ${media.phone`
            width: 50%;
            padding: 4px 5px 0;
        `}
    }

    ${ServiceLink} {
        display: flex;
        justify-content: space-between;
        padding-top: 12px;
        padding-bottom: 16px;
        border-top: 2px solid ${brown1};
        font-family: Basis Grotesque;
        font-weight: 700;
        font-size: 12px;
        line-height: 1;
        color: ${brown1};

        ${media.phone`
            font-size: 11px;
        `}
    }
`

// Services Grid

const ServiceGridTransition = styled(motion.div)``
const ServiceGridInfo = styled(motion.div)``
const ServiceGridLabel = styled(motion.div)``
const ImageWrapper = styled.div``
const ServiceGridBGImage = styled(motion.div)`
    background-image: url(${props => props.image});
    ${bgImage};
    height: 100%;
`
const ServicesGrid = styled.section`
    width: 100%;
    padding-top: 60px;
    padding-bottom: 180px;
    background-color: ${brown3};

    ${media.tabletLandscape`
        padding-bottom: 120px;
    `}

    ${media.tablet`
        padding-bottom: 80px;
    `}

    ${media.phone`
        padding-top: 24px;
        padding-bottom: 0px;
        background-color: white;
    `}

    ${Grid} {
        display: flex;
        flex-flow: row wrap;
        margin: 0 -10px -60px;

        ${media.tablet`
            margin-bottom: -32px;
        `}

        ${media.phone`
            display: block;
            margin: 0;
        `}
    }

    ${ServiceLink} {
        position: relative;
        display: inline-block;
        width: 50%;
        margin-bottom: 60px;
        padding: 0 10px;
        box-sizing: border-box;
        overflow: hidden;

        ${media.tablet`
            margin-bottom: 32px;
        `}

        ${media.phone`
            width: 100%;
            padding: 0;
            margin-bottom: 20px;
        `}
    }

    ${ImageWrapper} {
        overflow: hidden;
        height: 440px;
        background-color: black;

        ${media.tabletLandscape`
            height: 320px;
        `}

        ${media.tablet`
            height: 220px;
        `}
    }

    ${Description} {
        background-color: ${brown4};

        ${ServiceGridInfo} {
            padding: 20px 20px 60px;
            box-sizing: border-box;
            background-color: ${brown4};

            ${media.tabletLandscape`
                padding: 20px 20px 40px;
            `}
        }

        ${Heading} {
            ${heading2};
            color: ${brown1};

            ${media.phone`
                font-size: 24px;
            `}
        }

        ${ServiceGridLabel} {
            margin-top: 8px;
            ${links};
        }
    }
`

export const query = graphql`
    query {
        allWordpressInfopages(filter: { slug: { eq: "expertise" } }) {
            nodes {
                acf_json
            }
        }
    }
`

export default Expertise
